import classnames from "classnames";
import * as React from "react";
import "./Grid.style.scss";

export type GridColumnValues = 1 | 2 | 3 | 4 | 5 | 6;
interface GridProps {
  columns?: GridColumnValues | number;
  addClass?: string;
  gap?: boolean;
  children?: React.ReactNode;
}

export const Grid = (props: GridProps) => {
  const gridClass = classnames("component-grid", {
    "no-gap": !!props.gap,
    [`${props.addClass}`]: props.addClass,
    [`col-${props.columns}`]: props.columns,
  });
  return <div className={gridClass}>{props.children}</div>;
};

export default Grid;
